<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :disabled="isUpdate" :clearable="false"
                                  :options="branchOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>


                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Tên wifi" v-model="wifiInfo.wifiName"
                                  v-validate="'required'" name="wifiName"/>
                        <span class="text-danger text-sm">{{ errors.first('wifiName') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Wifi BSSID" v-model="wifiInfo.bssid"
                                  v-validate="'required'" name="BSSID"/>
                        <span class="text-danger text-sm">{{ errors.first('BSSID') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col lg:w-1/3 w-full mt-2">
                        <vs-checkbox v-model="wifiInfo.status" class="mb-1">Trạng thái hoạt động</vs-checkbox>
                    </div>
                </div>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end mb-5">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="wifiList">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    },
    data() {
        return {
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            branchOptions: [],
            wifiInfo: {},
            branchId: null,
            wifiId: null
        }
    },
    created() {
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');

        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.branchId = this.$route.query.branch_id;
        this.wifiId = this.$route.query.id;

        if (!this.wifiId)
            return this.wifiInfo = {
                wifiName: '',
                status: 1,
                streamUrl: '',
                organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
            };

        this.$vs.loading();
        this.$crm.post(`/wifi/find-by-id/${this.wifiId}`)
            .then((response) => {
                this.$vs.loading.close();
                if (response.data) {
                    this.isUpdate = true;
                    this.wifiInfo = response.data;
                }
            })
            .catch(() => {
                this.$vs.loading.close();
                this.wifiInfo = {
                    wifiName: '',
                    status: 1,
                    organizationBranchId: this.branchOptions.find(x => x.value.toString() === this.branchId) ? this.branchId : this.branchOptions[0].value
                }
            });
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.wifiInfo.organizationBranchId)),
                    value: this.wifiInfo.organizationBranchId
                }
            },
            set(obj) {
                this.wifiInfo.organizationBranchId = obj.value
            }
        },
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;

                let url = this.isUpdate ? `/wifi/update/${this.wifiInfo.id}` : `/wifi/create/${this.wifiInfo.organizationBranchId}`,
                    data = this.wifiInfo;
                data.status = data.status ? 1 : 0;
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} wifi thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$vs.loading.close();
                    this.$router.push(`/user/manage-wifi${this.branchId ? `?branch_id=${this.branchId}` : ''}`).catch(() => {
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        wifiList() {
            this.$router.push(`/user/manage-wifi${this.branchId ? `?branch_id=${this.branchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
